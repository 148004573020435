import axios from 'axios';
import AuthService from './auth-header';

// /*dev*/const API_URL = 'http://192.168.1.34:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.34:5000/api/';
}
class stackRankingService {

    async create(data) {
        try {
          //console.log(id)
          const res = await axios.post(API_URL + 'stack-ranking/',  data, 
          { 
            headers: AuthService.authHeaderForm()
          });
          console.log(res.data)
          return res.data
        }
        catch (err) {
          console.log(err.response);
          return err.response
        }
      }

      async getStackRanking(month, empId) {
        try {
          //console.log(id)
          const res = await axios.get(API_URL + 'stack-ranking/?month=' + month + '&emp_id=' + empId ,
          { 
            headers: AuthService.authHeader() 
          });
          console.log(res.data)
          return res.data
        }
        catch (err) {
          console.log(err.response);
          return err.response
        }
      }
      async refreshStackRanking(month, emp_id) {
        try {
          //console.log(id)
          const res = await axios.get(API_URL + 'stack-ranking/refresh/' + month + '?emp_id=' + emp_id,
          { 
            headers: AuthService.authHeader() 
          });
          console.log(res.data)
          return res.data
        }
        catch (err) {
          console.log(err.response);
          return err.response
        }
      }
      // async getTop3(month) {
      //   try {
      //     //console.log(id)
      //     const res = await axios.get(API_URL + 'stack-ranking/' + month ,
      //     { 
      //       headers: AuthService.authHeader() 
      //     });
      //     console.log(res.data)
      //     return res.data
      //   }
      //   catch (err) {
      //     console.log(err.response);
      //     return err.response
      //   }
      // }  
      

}
export default new stackRankingService