<template>
  <div v-if="$can('read','stack_ranking')">
    <b-card no-body>
        <b-row class="m-3">
            <b-col col>
                <b-form-group
                    label="Select Month"
                    label-for="select-month">
                    <v-select
                        v-model="monthSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="monthOptions"
                        :reduce="(option) => option.title"
                    />
                </b-form-group>
            </b-col>
            <b-col col>
                <b-form-group
                    label="Select Location"
                    label-for="select-location">
                    <v-select
                        v-model="locationSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="locationOptions"
                        :reduce="(option) => option.title"
                    />
                </b-form-group>
            </b-col>
            <b-col col v-if="$can('read','stack_ranking')">
                <b-button variant="primary" class="mt-2"
                @click="getStackRankingData"
                ><feather-icon
                    icon="ArrowRightIcon"
                    size="24"
                /></b-button>
            </b-col>
            <b-col col v-if="refreshAccess && $can('create','stack_ranking')">
                <b-button variant="secondary" class="mt-2"
                @click="refreshStackRankingData"
                > Refresh Data <feather-icon
                    icon="RefreshCwIcon"
                    size="16"
                /></b-button>
            </b-col>
        </b-row>      
    </b-card>
    <b-card>
        <b-tabs style="width:100%">
            <b-tab title="Recruiter" >
                <b-row>
      
                    <b-col
                        md="2"
                        sm="3"
                        lg="2"
                        class="my-1"
                        style="font-weight: 430;"
                    > Total Candidates : {{ totalRowsRecruiters }}
                    </b-col>
                    <b-col v-if="$can('read','manager_filter')"
                        md="2"
                        sm="2"
                        lg="2"
                        class="my-1"
                        style="font-weight: 430;"
                        > <b-button variant="secondary" 
                    @click="exportToExcel('stack-ranking')"
                    > Download <feather-icon
                        icon="ArrowDownIcon"
                        size="16"
                    /></b-button>
                    </b-col>
                    <b-col
                        md="8"
                        sm="8"
                        lg="8"
                        class="my-1"
                    >
                        <b-form-group
                        label="Filter"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            id="filterInput"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"
                            trim
                            />
                            <b-input-group-append>
                            <b-button
                                :disabled="!filter"
                                @click="filter = ''"
                            >
                                Clear
                            </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        </b-form-group>
                    </b-col>
                    
                    <b-col cols="12">
                        <span class="text-danger">{{ error }} </span>
                    </b-col>
                    
                    
                    <b-col cols="12">
                        <b-table
                        id="stack-ranking"
                        responsive
                        :items="recruitersStackRanking"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :filter="filter"
                        @filtered="onFiltered"
                        style="width: 100% !important"
                        >
                        <template #thead-top="">
                            <b-tr>
                                <b-th colspan="1"><span class="sr-only">Recruiter</span></b-th>
                                <b-th variant="primary" class="text-center" colspan="2">Revenue</b-th>
                                <b-th variant="secondary" class="text-center" colspan="2">Quality - Drops</b-th>
                                <b-th variant="secondary" class="text-center" colspan="2">Number of Profiles</b-th>
                                <b-th variant="secondary" class="text-center" colspan="2">LinkedIn Post</b-th>
                                <b-th variant="secondary" class="text-center" colspan="2">Closures</b-th>
                                <b-th variant="secondary" class="text-center" colspan="3">Performance Result</b-th>
                                <b-th colspan="1"><span class="sr-only">Incentive</span></b-th>
                            </b-tr>
                        </template>
                        <!-- <template #cell(emp_name)="data">
                            <span class="text-primary">{{ data.item.emp_name }}</span>
                        </template> -->
                            
                        </b-table>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Team Leads">
                <b-row>
      
                    <b-col
                        md="2"
                        sm="2"
                        lg="2"
                        class="my-1"
                        style="font-weight: 430;"
                    > Total Candidates : {{ totalRowsTeamLeads }}
                    </b-col>
                    <b-col v-if="$can('read','manager_filter')"
                        md="2"
                        sm="2"
                        lg="2"
                        class="my-1"
                        style="font-weight: 430;"
                        > <b-button variant="secondary" 
                    @click="exportToExcel('stack-ranking')"
                    > Download <feather-icon
                        icon="ArrowDownIcon"
                        size="16"
                    /></b-button>
                    </b-col>
                    
                    <b-col
                        md="8"
                        sm="8"
                        lg="8"
                        class="my-1"
                    >
                        <b-form-group
                        label="Filter"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            id="filterInput"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"
                            trim
                            />
                            <b-input-group-append>
                            <b-button
                                :disabled="!filter"
                                @click="filter = ''"
                            >
                                Clear
                            </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <span class="text-danger">{{ error }} </span>
                    </b-col>
                    
                    
                    <b-col cols="12">
                        <b-table
                        id="stack-ranking-tl"
                        responsive
                        :items="teamLeadsStackRanking"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :filter="filter"
                        @filtered="onFiltered"
                        style="width: 100% !important"
                        >
                        <template #thead-top="">
                            <b-tr>
                                <b-th colspan="1"><span class="sr-only">Recruiter</span></b-th>
                                <b-th variant="primary" class="text-center" colspan="2">Revenue</b-th>
                                <b-th variant="secondary" class="text-center" colspan="2">Quality - Drops</b-th>
                                <b-th variant="secondary" class="text-center" colspan="2">Number of Profiles</b-th>
                                <b-th variant="secondary" class="text-center" colspan="2">LinkedIn Post</b-th>
                                <b-th variant="secondary" class="text-center" colspan="2">Closures</b-th>
                                <b-th variant="secondary" class="text-center" colspan="3">Performance Result</b-th>
                                <b-th colspan="1"><span class="sr-only">Incentive</span></b-th>
                            </b-tr>
                        </template>
                            
                        </b-table>
                    </b-col>

                </b-row>
            </b-tab>
        </b-tabs>    
    
    </b-card>
  </div>
</template>
<script>
//import statements
import {
  BCard, BRow, BCol, BButton, BTable, BFormGroup, BTr, BTh, BTd, BInputGroup, BInputGroupAppend, BFormInput, BTabs, BTab
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import StackRankingService from '../../../services/stack-ranking-service'

export default {
  data() {
    return {
        monthSelected: '',
        monthOptions: [],
        locationSelected: null,
        locationOptions: [
            {'title': 'Pune'},{'title': 'Delhi'}
        ],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        recruitersStackRanking: [],
        teamLeadsStackRanking: [],
        fields: [
            { key: 'emp_name', label: 'Recruiter', sortable: true},
            { key: 'revenue', label: 'Revenue', sortable: true},
            { key: 'revenue_score', label: 'Revenue Score', sortable: true},
            { key: 'drops', label: 'Quality Drops %', sortable: true},
            { key: 'drops_score', label: 'Quality Drops Score', sortable: true},
            { key: 'profiles', label: 'Profiles %', sortable: true},
            { key: 'profiles_score', label: 'Profiles Score', sortable: true},
            { key: 'linkedin', label: 'LinkedIn %', sortable: true},
            { key: 'linkedin_score', label: 'LinkedIn Score', sortable: true},
            { key: 'closure', label: 'Closures %', sortable: true},
            { key: 'closure_score', label: 'Closures Score', sortable: true},
            { key: 'total_score', label: 'Total', sortable: true},
            { key: 'grade', label: 'Grade', sortable: true},
            { key: 'rank', label: 'Stack Ranking', sortable: true},
            { key: 'incentive', label: 'Incentive', sortable: true},
        ],
        error: null,
        totalRowsRecruiters: 0,
        totalRowsTeamLeads: 0,
        refreshAccess: false
    }
  },
  components: {
    BCard, BRow, BCol, BButton, BTable, vSelect, BFormGroup, BTr, BTh, BTd, BInputGroup, BInputGroupAppend, BFormInput, BTabs, BTab
    
  },
  created() {
    const currentDate = new Date();
    this.monthSelected = currentDate.toLocaleString('en-US', { month: 'short', year: 'numeric' }).replace(' ','-');
    //this.monthSelected = 'Jun-2023'
    console.log(this.monthSelected);
    this.getMonthList();


    
    const role = JSON.parse(localStorage.getItem('userData')).roles[0]
    // if( role === 'ADMIN' || role === 'MASTER' || role === 'MIS' || role === 'HR') {
    //     this.refreshAccess = true
    // }
    if( this.$can('manage','stack_ranking')) {
        this.refreshAccess = true
    }
    // if(this.$route.query) {
    //     this.monthSelected = this.$route.query.month
    // } else {
    //     this.$route.query.month = this.monthSelected
    // }

    this.getStackRankingData();
  },
  methods: {
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
    getMonthList() {
        const startDate = new Date(2023, 3); // April is represented by index 3
        const currentDate = new Date();
        //const monthsList = [];

        for (let date = startDate; date <= currentDate; date.setMonth(date.getMonth() + 1)) {
            const formattedDate = date.toLocaleString('en-US', { month: 'short', year: 'numeric' }).replace(' ','-');
            const monthObject = { title: formattedDate };
            this.monthOptions.push(monthObject);
        }
        this.monthOptions.reverse();
        console.log('month options: ', this.monthOptions);
    },
    getStackRankingData () {
        
        StackRankingService.getStackRanking(this.monthSelected,null).then(res => {
            if (res.status === 'OK') {
                this.recruitersStackRanking = res.data.filter(item => item.emp_type === 'talent-advisor-sourcer')
                this.teamLeadsStackRanking = res.data.filter(item => item.emp_type === 'account-manager')

                if(this.locationSelected) {
                    this.recruitersStackRanking = this.recruitersStackRanking.filter(item => item.location === this.locationSelected)
                    this.teamLeadsStackRanking = this.teamLeadsStackRanking.filter(item => item.location === this.locationSelected)
                }
                this.totalRowsRecruiters = this.recruitersStackRanking.length
                this.totalRowsTeamLeads = this.teamLeadsStackRanking.length


            } else if ((res.status === 401 ) || (res.status === 403 )) {
                window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
                localStorage.clear(); 
                this.$router.push({ name: "auth-login" });
            } else if (res.status === 404 ) { 
                alert("Data Not Found, some error occurred: " + res.status + '\n' + JSON.parse(JSON.stringify(res.data)) + '\n' + res.statusText);
            } else {
                alert("Error fetching data: " + JSON.parse((JSON.stringify(res))));
            }
        })
    },
    refreshStackRankingData() {
        const emp_id = JSON.parse(localStorage.getItem('userData')).emp_id
        StackRankingService.refreshStackRanking(this.monthSelected, emp_id).then(res => {
            if (res.status === 'OK') {
                
                this.getStackRankingData();


            } else if ((res.status === 401 ) || (res.status === 403 )) {
                window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
                localStorage.clear(); 
                this.$router.push({ name: "auth-login" });
            } else if (res.status === 404 ) { 
                alert("Data Not Found, some error occurred: " + res.status + '\n' + JSON.parse(JSON.stringify(res.data)) + '\n' + res.statusText);
            } else if (res.status === 'FAILED') {
                alert("Error fetching data: " + res.message);
            } else {
                alert("Error fetching data: " + JSON.parse((JSON.stringify(res))));
            }
        })
    },
    exportToExcel(id) {
        const table = document.getElementById(id);
        const worksheet = 'Worksheet';
        const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
            <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head>
            <body><table>${table.innerHTML}</table></body>
        </html>`;

        const base64 = (s) => window.btoa(unescape(encodeURIComponent(s)));
        const format = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p]);

        const ctx = { worksheet, table: table.innerHTML };
        const uri = 'data:application/vnd.ms-excel;base64,' + base64(format(template, ctx));

        //Create a hidden anchor element and trigger the download
        const link = document.createElement('a');
        link.href = uri;
        link.download = 'data.xls'; // Change the filename if needed
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    },
//   mounted() {
//     // Initialize TableExport when the component is mounted
//     TableExport(document.getElementById('stack-ranking'), {
//       formats: ['xlsx'], // You can add more formats like 'csv', 'txt', etc.
//     });
//   },
    
}
</script>
<style scoped lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";
    .btn {
        text-align: center;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0.386rem .5rem;
        border-radius: 0.358rem;
    }
  </style>